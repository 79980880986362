import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import tw from "twin.macro"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      thumb: file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <CustomWrapper id="contact">
      <MainWrapper>
        <Wrapper>
          <div className="column">
            <h2>Let's get in touch</h2>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              className="contact_form"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <label>
                Name
                <input type="text" name="name" id="name" required />
              </label>
              <label>
                Email
                <input type="email" name="email" id="email" required />
              </label>
              <label>
                Subject
                <input type="text" name="subject" id="subject" />
              </label>
              <label>
                Message
                <textarea name="message" id="message" rows="5" required />
              </label>
              <button type="submit" className="submit-btn">
                Send message
              </button>
            </form>
          </div>
          <div className="column">
            <Img fluid={data.thumb.childImageSharp.fluid} className="thumb" />
          </div>
        </Wrapper>
      </MainWrapper>
    </CustomWrapper>
  )
}

export default Contact

// styles

const CustomWrapper = styled.div`
  ${tw`pt-24`};
  background: linear-gradient(
    -126deg,
    rgba(18, 18, 18, 1) -30%,
    rgba(0, 0, 0, 1) 92%,
    rgba(24, 24, 24, 1) 430%
  );
  margin-top: -6rem;
  padding-top: 6rem;
`

const MainWrapper = styled.div`
  ${tw`container mx-auto pt-24`};
`

const Wrapper = styled.div`
  ${tw`flex flex-wrap items-center sm:items-center`}

  & .column {
    ${tw`w-full sm:w-1/2`}

    & h2 {
      ${tw`text-xl pl-6`}
    }
    & .contact_form {
      ${tw`flex flex-col pt-12 p-8`}

      & label {
        ${tw`inline-grid text-sm font-light mb-2`};
        color: #e0e0e0;
        transition: all 200ms ease-in-out;
        cursor: pointer;

        &:focus-within {
          color: #878787;
        }

        & input,
        textarea {
          ${tw`my-4 text-lg font-light tracking-wide`};
          background: transparent;
          border-bottom: 1px solid #878787;
          outline: none;
          transition: all 200ms ease-in-out;
          color: #fff;
          &:focus {
            border-bottom: 1px solid #fff;
          }
        }
      }
      .submit-btn {
        display: inline;
        margin: 0 auto;
        border-bottom: 1px solid #878787;
        padding-bottom: 3px;
        outline: none;
        transition: all 200ms ease-in-out;

        &:hover,
        &:active {
          color: #fff;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
`
