import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"
import tw from "twin.macro"
import Img from "gatsby-image"

const MainWrapper = styled.div`
  background: linear-gradient(
    -66deg,
    rgba(18, 18, 18, 1) -30%,
    rgba(0, 0, 0, 1) 92%,
    rgba(24, 24, 24, 1) 430%
  );
`
const Wrapper = styled.div`
  ${tw`container mx-auto py-10 sm:py-0`}

  & h2 {
    font-size: clamp(0.7rem, 2.5vw, 1.5rem);
  }
  & .heading {
    ${tw`text-xl pt-0 sm:pt-10`}
    text-align: center;
  }

  & .work {
    ${tw`flex flex-wrap m-0 pt-4 sm:m-16 -mx-8 sm:pt-20`}

    & .work_thumb {
      ${tw`w-full sm:w-1/2 px-4 sm:pr-10 px-12 `}

      & .gatsby-image-wrapper {
        width: 100%; 
      }
    }
    & .work_info {
      ${tw`w-full sm:w-1/2 px-4 text-sm leading-normal pt-4 px-12`}

      & .links {
        /* TODO Create reuseable Custom btn */
        .primary-btn {
          ${tw`text-white font-semibold py-2 px-4 rounded-full mt-4 sm:mt-20 text-sm tracking-wider capitalize mr-4`}
          background: #2196f3;
          transition: 100ms all ease-in-out;
          color: #fafafa;
          border: 1px solid #2196f3;

          &:hover {
            background: transparent;
            border: 1px solid #2196f3;
            color: #fafafa;
          }
        }
        .secondary-btn {
          transition: 100ms all ease-in-out;
          border: 1px solid #2196f3;
          color: #fafafa;

          ${tw`bg-transparent font-semibold hover:text-white py-2 px-4 rounded-full`}

          &:hover {
            background: #2196f3;
            color: #fafafa;
          }
        }
      }
    }
  }
  .reversed {
    ${tw`flex-row-reverse`}
    padding-top: 10rem;
  }
`

const Work = () => {
  const data = useStaticQuery(graphql`
    query {
      mv: file(relativePath: { eq: "mv.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      blaze: file(relativePath: { eq: "blaze.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hat: file(relativePath: { eq: "Hat.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <MainWrapper id="work">
      <Wrapper>
        <h2 className="heading white-text">Works</h2>
        {/* Work 2 */}
        <div className="work reversed">
          <div className="work_thumb">
            <Img fluid={data.mv.childImageSharp.fluid} className="profile" />
          </div>
          <div className="work_info">
            <p>
              This website is build using React with the create-react-app and
              the the React hooks, where I fetched movies from the TMDB API and
              desinged the components using tailwind and styled components.
            </p>
            <br />
            <p>
              <i className="white-text">React - Styled components - Tailwind</i>
            </p>
            <div className="links">
              <button className="primary-btn">
                <a
                  href="https://movie-lib7.netlify.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit
                </a>
              </button>
              <button className="secondary-btn">
                <a
                  href="https://github.com/ezatla/Movie-lib-react"
                  target="_blank"
                  rel="noreferrer"
                >
                  Source
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* Work 2 */}
        <div className="work">
          <div className="work_thumb">
            <Img fluid={data.blaze.childImageSharp.fluid} className="profile" />
          </div>
          <div className="work_info">
            <p>
              This website is a built with my intention to explore and learn liquid and Alpinejs, hence
              this project I started was from scratch I mainly focused on exploring the shopify admin panel and
              the template laungage liquid, I used Tailwindcss for the UI and Alpinejs for some utilities ( dropdown, tabs), then I tweaked
              the design of the website to my like.
              <br />
              since the store is in development it requires password to enter
              <br />
              <br />
              <b>Password: 1</b>
            </p>
            <br />
            <p>
              <i className="white-text">
                Liquid - Tailwind - Alpinejs - Scss
              </i>
            </p>
            <br />
            <br />
            <div className="links">
              <button className="primary-btn">
                <a
                  href="https://blaze-ecommerce.myshopify.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit
                </a>
              </button>
              <button className="secondary-btn">
                <a
                  href="https://github.com/ezatla/Blaze"
                  target="_blank"
                  rel="noreferrer"
                >
                  Source
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* Work 3 */}
        <div className="work reversed">
          <div className="work_thumb">
            <Img
              fluid={data.hat.childImageSharp.fluid}
              className="profile"
            />
          </div>
          <div className="work_info">
            <p>
              This website uses firebase as its backend and stripe as its payment system, I used redux for the state management with firebase as backend
              it uses google auth for user authentication. I expored and learned quite alot about Expressjs and Redux in this project.
            </p>
            <br />
            <p>
              <i className="white-text">
                React - Redux - Expressjs - Styled components - Firebase
              </i>
            </p>

            <div className="links">
              <button className="primary-btn">
                <a
                  href="https://hat-ecommerce.herokuapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit
                </a>
              </button>
              <button className="secondary-btn">
                <a
                  href="https://github.com/ezatla/tova-ecom"
                  target="_blank"
                  rel="noreferrer"
                >
                  Source
                </a>
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  )
}

export default Work
