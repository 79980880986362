import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import tw from "twin.macro"

const MainWrapper = styled.div`
  background: #000;`

const Wrapper = styled.div`
  ${tw`container mx-auto`}
  /* overflow: hidden; */
  /* clip-path: inset(0vw 0vw -14vw -10vw ); */

  & .about-content {
    ${tw`flex items-center sm:items-center`}
    color: #fff;

    & .profile {
      filter: drop-shadow(-220px -140px 46px #313131) contrast(1.1)
        brightness(1) grayscale(100);
    }

    & .about-info {
      ${tw`w-2/4 px-4 relative`}
      padding: 18% 0;

      @media(max-width: 576px){
          padding-top: 25%;
        }

      h1{
        font-size: clamp(0.7rem, 3.5vw, 2rem);
      }
      h2 {
        ${tw`text-2xl`}
        font-size: clamp(0.7rem, 2.5vw, 1.5rem);
      }
      h1{
        color: #fff;
      }
      p {
        ${tw`text-sm leading-normal`}
        font-size: clamp(0.2rem, 2.5vw, 0.9rem);
      }
      .hr-line {
        height: 1px;
        background: #b2b2b2;
        width: 11rem;
        position: absolute;
        top: 260px;
        left: 5%;

        @media(max-width: 1300px){
          display: none;
        }
      }
    }

    & .about-img {
      ${tw`w-2/4 px-4`}

      & .gatsby-image-wrapper {
        width: 100%;
        overflow: unset !important;

        & img {
          left: -8vw !important;
          @media (min-width: 1092.98px) {
            left: -34% !important;
          }
          @media (min-width: 1492.98px) {
            left: -64% !important;
          }
          @media (min-width: 1992.98px) {
            left: -86% !important;
          }
        }
      }
    }
    & .resume-btn {
      ${tw`text-white font-semibold py-2 px-4 rounded-full mt-20 text-sm tracking-wider capitalize`}
      background: #2196f3;
      transition: 100ms all ease-in-out;
      color: #e1e1e1;
      border: 1px solid #2196f3;

      &:hover {
        background: transparent;
        border: 1px solid #2196f3;
        color: #e1e1e1;
      }
    }
  }
`

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      profileImg: file(relativePath: { eq: "EZI.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      # pdf: file(name: { eq: "resume" }) {
      #   name
      #   publicURL
      # }
    }
  `)
  return (
    <MainWrapper id="about">
      <Wrapper>
        <div className="about-content">
          <div className="about-img">
            <Img
              fluid={data.profileImg.childImageSharp.fluid}
              className="profile"
            />
          </div>
          <div className="about-info">
            <span className="hr-line"></span>
            <h2 className="grey-text">Hi There ✌,</h2>
            <h1>I'M Ezzatulla</h1>
            <p className="grey-text">
              A front-end developer who loves to build <br /> creative, elegent
              user interfaces{" "}
            </p>
            {/* <button className="resume-btn"><a  href={data.pdf.publicURL}  target="_blank" rel="noreferrer">Resume</a></button> */}
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  )
}

export default About
