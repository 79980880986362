import React from "react"
import tw from "twin.macro"

import SEO from "../components/seo"

import About from "../sections/about"
import Work from "../sections/work"
import Contact from "../sections/contact"

const Button = tw.button`bg-blue-600`

const IndexPage = () => (
  <>
    <SEO title="Ezzatulla" />
    <About />
    <Work />
    <Contact />
    {/* <Button>Hello there</Button> */}
  </>
)

export default IndexPage
